@import url('https://fonts.googleapis.com/css?family=Alegreya:400,400i,700,700i|Roboto+Mono');

.cell{
  // border: 1px solid red;
}

%mono{
  font-family: "Roboto Mono";
  text-transform: uppercase;
  letter-spacing: .13em;
}

h1, h2{
  margin-top: 2rem;
  margin-bottom: 2rem;
}
h4{
  font-weight: bold;
  margin-top: .6rem;
  margin-bottom: .6rem;
}

p{
  margin-top: .6rem;
  margin-bottom: .6rem;
}

div.grid-x.block{
  margin-top: 5rem;
  margin-bottom: 5rem;
}

#companyname{
  @extend %mono;
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  background-color: $white;
}

#motto h1{
  margin-top: 0;
  margin-bottom: 3rem;
}

#bannerimg{
  background: url("../img/IMG_3243.jpg") 75% no-repeat;
  margin-top: 1.5rem;
  margin-bottom: 0;
  width: 100%;
  height: 45vw;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  display: flex;
  background-position: left;
}

#getstarted-img{
  background: url("../img/17.jpg") 50% no-repeat;
  background-size: cover;
  display: flex;
  background-position: bottom;
  @include breakpoint(small only) {
    height: 20vh;
  }
}

.button.large{
  @extend %mono;
  font-size: .95rem;
  padding: 1.4rem 2rem;
  border-radius: 3px;

  &.holy{
    background: transparent;
    border: 2px solid lighten($primary-color, 50%);
    color: $dark-gray;
    &:hover{
      border: 2px solid lighten($primary-color, 35%);
    }
  }
}

#schedulebtn{
  @include breakpoint(small only){
    margin-top: 1.5rem;
  }
}

.img-caption{
  background-color: rgb(248,248,248);
  padding: 2rem 3.5rem;

  .button.large{
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

#last-thing{
  padding-bottom: 1rem;
}

#footer{
  @extend %mono;
  letter-spacing: normal;
  line-height: 1.5rem;
  font-size: .8rem;
  color: $light-gray;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color  : $dark-gray;

  a{
    color: lighten($primary-color, 50%);
  }
}
